import gsap from 'gsap'
import { getElement } from './_utilities'

export const IconTextTransition = function(selectorOrElement) {
    let $element = getElement(selectorOrElement)
    return gsap.timeline({
        scrollTrigger: {
            trigger: $element,
            scrub: true,
            end: 'bottom top',
        },
    })
        .fromTo($element, {
            x: -30,
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 2,
        })

        .to($element, {
            y: -30,
            opacity: 0,
            duration: 2,
        }, '>3')
}

export const HeadlineTextTransition = function (selectorOrElement) {
    let $element = getElement(selectorOrElement)
    const $headlineParts = $element.querySelectorAll('.headline span')
    const $text = $element.querySelector('.text')
    const $arrow = $element.querySelector('.arrow')

    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: $element,
            scrub: true,
            start: 'top bottom-=200',
            end: 'bottom center',
        }
    })
        .fromTo($headlineParts, {
            opacity: 0,
            y: 30,
        }, {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: .25,
        })
    
        .fromTo($text, {
            opacity: 0,
            y: 30,
        }, {
            opacity: 1,
            y: 0,
            duration: 1,
        }, '<')
    
        .fromTo($arrow, {
            opacity: 0,
            x: -30,
        }, {
            opacity: 1,
            x: 0,
            duration: 1,
        }, '<')

    return tl
}