import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { isMobile } from './_utilities'
import { MEDIA_QUERIES } from './_variables'
import { HeadlineTextTransition, IconTextTransition } from './_animations'
import { FullScreenTestimonial, IconBarSection, ServicesIntroSection } from './_sections'
import Navigation from './_navigation'

gsap.registerPlugin(ScrollTrigger)

const $PageHeader = document.querySelector('.page-header')
const $LoadingIndicator = document.querySelector('.loading-indicator')
const $ScrollIndicator = document.getElementById('scroll-indicator')

// SITE LOGO
document.querySelector('.site-logo').addEventListener('click', (e) => {
    Navigation.setActiveLink(null) 
})

// LOAD
let loadTl = gsap.timeline({
    paused: true,
    onStart() {
        $LoadingIndicator.remove()
    },
    onComplete() {
        document.documentElement.classList.remove('loading')
    }
}).to('body > main', { opacity: 1 })

window.addEventListener('load', e => {
    loadTl.play()
})

// Set full screen elements to screen.height on mobile devices to 
//reduce jumping content as much as possible
if (isMobile()) {
    gsap.set('#hero, #hero .overlay, .full-screen-testimonial', {
        height: screen.height
    })

    gsap.set($ScrollIndicator, {
        bottom: `calc(${screen.height - window.innerHeight}px + 3.125rem)` 
    })
}

// ### SECTION: HERO

// Slideshow
let SlideshowTimeline = gsap.timeline({
    onComplete() {
        this.restart() // Loop
    }
})

document.querySelectorAll('#hero .hero-image').forEach(($image, index, items) => {
    SlideshowTimeline.to($image, {
        opacity: 0,
        duration: 1,
    }, '>2')

    // Fade first image in after last image
    if ((index + 1) === items.length) {
        SlideshowTimeline.to(items[0], {
            opacity: 1,
            duration: 1,
        }, '<')
    }
})

let brandingAnimation = gsap.timeline({
    scrollTrigger: {
        trigger: '#hero',
        pin: true,
        scrub: true,
        end: '+=200%',
    },
})
    .to($ScrollIndicator, {
        opacity: 0
    })

brandingAnimation

    .fromTo('#hero .overlay', {
        opacity: 0,
    }, {
        opacity: 0.85,
        duration: 1.5,
    })

    .add(function() {
        if (brandingAnimation.scrollTrigger.direction > 0) {
            $PageHeader.classList.add('page-header--inverted')
        } else {
            $PageHeader.classList.remove('page-header--inverted')
        }
    })

    .fromTo('#logo', {
        opacity: 0,
        scale: 5,
    }, {
        opacity: 1,
        scale: 1,
        duration: 2,
    }, '<')

    .from('#claim', {
        opacity: 0,
        y: 50,
        duration: 2,
    }, '<')

    .to('#claim, #logo', {
        opacity: 0,
        duration: 1.5,
    }, '+=2')


// ### SECTION: SERVICES

ServicesIntroSection(document.querySelector('.services-section-intro'))

FullScreenTestimonial('.services-testimonial-1')

ScrollTrigger.create({
    trigger: '.services-section-list',
    scrub: true,
    start: 'top top',
    end: 'bottom bottom',
})

document.querySelectorAll('.services-section-list .icon-text-item').forEach(($iconTextElement) => {
    IconTextTransition($iconTextElement)
})

// ## SECTION: ICON BAR

IconBarSection('.services-section-bullets')

FullScreenTestimonial('.services-testimonial-2')

HeadlineTextTransition('.section-career-intro')

ScrollTrigger.create({
    trigger: '.section-career-intro',
    scrub: true,
    start: 'top top',
    end: 'bottom bottom',
})

document.querySelectorAll('.image-text').forEach($element => {

    gsap.timeline({
        scrollTrigger: {
            trigger: $element,
            scrub: true,
            start: 'top bottom',
            end: 'top center',
        },
    })
        .fromTo($element.querySelector('.text'), {
            opacity: 0,
            y: 30,
        }, {
            opacity: 1,
            y: 0,
        })
    
        .fromTo($element.querySelector('.image'), {
            opacity: 0,
            x: -30,
        }, {
            opacity: 1,
            x: 0,
        }, '<')

})

const $featureBox = document.querySelector('#feature-box')
const $arrowBoxLeft = document.querySelector('#arrow-box-left')
const $arrowBoxRight = document.querySelector('#arrow-box-right')

ScrollTrigger.matchMedia({

    [MEDIA_QUERIES.lg.min]: () => {
        $featureBox.classList.remove('static')
        
        gsap.set($featureBox, { clearProps: true })
        
        gsap.set($featureBox, { position: 'fixed' })
        gsap.set('#benefits', { clearProps: true })
        gsap.set('#benefits', { height: 'calc(var(--vh, 1vh) * 150)' })

        gsap.timeline({
            scrollTrigger: {
                trigger: '#benefits',
                scrub: true,
                start: 'top center-=100',
                end: 'bottom center',
            }
        })
            .fromTo($arrowBoxLeft, {
                display: 'block',
                opacity: 0,
                boxShadow: '0 0 2rem 2rem black',
            }, {
                x: () => {
                    let bbox = $arrowBoxLeft.getBoundingClientRect()
                    return ( window.innerWidth / 2 ) - bbox.width + 220
                },
                opacity: 1,
                boxShadow: 'none',
                duration: 5,
            }, '<')
        
            .fromTo($arrowBoxRight, {
                display: 'block',
                opacity: 0,
                boxShadow: '0 0 2rem 2rem black',
            }, {
                x: () => {
                    let bbox = $arrowBoxRight.getBoundingClientRect()
                    return (((window.innerWidth / 2) - ( window.innerWidth - bbox.x )) * -1) - 220
                },
                opacity: 1,
                boxShadow: 'none',
                duration: 5,
            }, '<')
        
            .fromTo($featureBox, {
                opacity: 0,
                y: 200,
            }, {
                opacity: 1,
                y: 0,
                duration: 5,
            }, '<')
        
            .to('#benefits', {
                opacity: 0,
                duration: 2,
            }, '<8')
    },
    [MEDIA_QUERIES.md.max]: () => {
        gsap.set($featureBox, { clearProps: true })
        gsap.set('#benefits', { clearProps: true })
        $featureBox.classList.add('static')
    }
})

HeadlineTextTransition('.section-career-offers')

FullScreenTestimonial('.career-testimonial-2')

gsap.timeline({
    scrollTrigger: {
        trigger: '.section-career-offers .contact-widget',
        scrub: true,
        end: 'top center'
    }
})
    .fromTo('.section-career-offers .contact-widget', {
        y: 50,
        opacity: 0,
    }, {
        y: 0,
        opacity: 1,
    }, '>')

const $factsFigures = document.querySelectorAll('.section-about-history .facts-figures')

// ### SECTION: FACTS & FIGURES
ScrollTrigger.matchMedia({
    [MEDIA_QUERIES.md.min]: () => {
        gsap.set('.section-about-history .figure', { clearProps: true })
        gsap.set('.section-about-history .fact', { clearProps: true })

        gsap.set('.section-about-history .figure', {
            position: 'fixed',
            top: '50%', 
            yPercent: -50,
        })

        $factsFigures.forEach($factsFigure => {
            const $figure = $factsFigure.querySelector('.figure')
            const $fact = $factsFigure.querySelector('.fact')
        
            gsap.timeline({
                scrollTrigger: {
                    trigger: $fact,
                    scrub: true,
                    start: 'top bottom',
                    end:  'bottom top',
                }
            })
                .from($fact, {
                    opacity: 0,
                    duration: 1,
                })
        
                .to($fact, {
                    opacity: 0,
                    duration: 1,
                }, '>2')
        
            gsap.timeline({
                scrollTrigger: {
                    trigger: $fact,
                    scrub: true,
                    start: 'top-=100 center',
                    end: 'bottom+=100 center',
                }
            })
                .from($figure, {
                    opacity: 0,
                    duration: 2,
                })
        
                .to($figure, {
                    opacity: 0,
                    duration: 1,
                }, '>3')
        })
    },
    [MEDIA_QUERIES.sm.max]: () => {
        gsap.set('.section-about-history .figure', { clearProps: true })
        gsap.set('.section-about-history .fact', { clearProps: true })

        $factsFigures.forEach($factsFigure => {
            const $figure = $factsFigure.querySelector('.figure')
            const $fact = $factsFigure.querySelector('.fact')
        
            gsap.timeline({
                scrollTrigger: {
                    trigger: $factsFigure,
                    scrub: true,
                    start: 'top bottom',
                    end:  'bottom top',
                }
            })
                .from([ $fact, $figure ], {
                    opacity: 0,
                    x: -50,
                    duration: 2,
                })
        
                .to([ $fact, $figure ], {
                    opacity: 0,
                    y: 30,
                    duration: 2,
                }, '>2')
        })
    }
})


ScrollTrigger.create({
    trigger: '.section-about-values',
    pin: '.circle-values',
    start: 'top top',
    end: 'bottom center'
})

gsap.timeline({
    scrollTrigger: {
        trigger: '.section-about-values',
        scrub: true,
        start: 'top center',
        end: 'bottom bottom'
    }
})
    .from('.section-about-values .headline span', {
        opacity: 0,
        y: -30,
        duration: 1,
    })

    .from('.section-about-values .column', {
        y: -30,
        opacity: 0,
        stagger: 1,
        duration: 2,
    })

HeadlineTextTransition('.section-contact-details')

ScrollTrigger.create({
    trigger: '#about',
    start: 'top top+=122',
    onEnter() {
        $PageHeader.classList.remove('page-header--inverted')
    },
    onLeaveBack() {
        $PageHeader.classList.add('page-header--inverted')
    },
})

// Handle section entry and exit to update navigation active state
document.querySelectorAll('#hero, #services, #career, #about, #contact').forEach($section => {

    ScrollTrigger.create({
        trigger: $section,
        start: 'top center',
        end: 'bottom center',

        onEnter({trigger}) {
            let id = trigger.getAttribute('id')
            let $link = Navigation.getLinkByHref('#' + id)

            if ($link) {
                Navigation.setActiveLink($link)
            } else {
                Navigation.resetActiveLink()
            }
        },

        onEnterBack({trigger}) {
            let id = trigger.getAttribute('id')
            let $link = Navigation.getLinkByHref('#' + id)

            if ($link) {
                Navigation.setActiveLink($link)
            } else {
                Navigation.resetActiveLink()
            }
        },
    })
})