export const COLORS = {
    darkblue:       'rgb(0, 59, 92)',
    purple:         'rgb(167, 14, 195)',
    aquamarin:      'rgb(0, 227, 231)',
    darkaquamarin:  'rgb(0, 100, 100)',
    oceangreen:     'rgb(0, 85, 248)',
    blackpurple:    'rgb(84, 7, 98)',
}

export const BREAKPOINTS = {
    xs: {
        min: 0,
        max: 549,
    },
    sm: {
        min: 550,
        max: 767,
    },
    md: {
        min: 768,
        max: 939,
    },
    lg: {
        min: 940,
        max: 1099,
    },
    xl: {
        min: 1100,
        max: 1279,
    },
    '2xl': {
        min: 1280,
        max: 1599,
    },
    '3xl': {
        min: 1600,
        max: null,
    },
}


export const MEDIA_QUERIES = {
    xs: {
        min: `(min-width: ${BREAKPOINTS.xs.min}px)`,
        max: `(max-width: ${BREAKPOINTS.xs.max}px)`,
    }, 
    sm: {
        min: `(min-width: ${BREAKPOINTS.sm.min}px)`,
        max: `(max-width: ${BREAKPOINTS.sm.max}px)`,
    },
    md: {
        min: `(min-width: ${BREAKPOINTS.md.min}px)`,
        max: `(max-width: ${BREAKPOINTS.md.max}px)`,
    },
    lg: {
        min: `(min-width: ${BREAKPOINTS.lg.min}px)`,
        max: `(max-width: ${BREAKPOINTS.lg.max}px)`,
    },
    xl: {
        min: `(min-width: ${BREAKPOINTS.xl.min}px)`,
        max: `(max-width: ${BREAKPOINTS.xl.max}px)`,
    },
    '2xl': {
        min: `(min-width: ${BREAKPOINTS['2xl'].min}px)`,
        max: `(max-width: ${BREAKPOINTS['2xl'].max}px)`,
    }, 
    '3xl': {
        min: `(min-width: ${BREAKPOINTS['3xl'].min}px)`,
        max: `(max-width: ${BREAKPOINTS['3xl'].max}px)`,
    }
}