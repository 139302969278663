import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { MEDIA_QUERIES } from './_variables'
import { IconTextTransition } from './_animations'
import { isDesktop, getElement } from './_utilities'

export const FullScreenTestimonial = function (selectorOrElement, config = {}) {
    let $element = getElement(selectorOrElement)
    let $bgImage = $element.querySelector('.background-image')
    let $quote = $element.querySelector('.quote')

    config = Object.assign({
        scrollTrigger: {
            trigger: $element,
            scrub: true,
            start: 'top bottom',
            end: 'bottom top',
        },
    }, config)

    if (isDesktop()) {
        config.scrollTrigger = Object.assign(config.scrollTrigger, {
            onEnter() {
                $element.classList.add('fixed')
            },
            onLeave() {
                $element.classList.remove('fixed')
            },
            onEnterBack() {
                $element.classList.add('fixed')
            },
            onLeaveBack() {
                $element.classList.remove('fixed')
            },
        })
    }

    ScrollTrigger.saveStyles($bgImage)

    ScrollTrigger.matchMedia({
        [MEDIA_QUERIES.md.min]: () => {
            gsap.set($element, { height: 'calc(var(--vh, 1vh) * 150)' })
            gsap.set([$bgImage, $quote], { opacity: 0 })

            gsap.timeline(config)

                .fromTo([$bgImage, $quote], {
                    opacity: 0, 
                }, { 
                    opacity: 1, 
                    duration: 1.25,
                })
        
                .to([$bgImage, $quote], {
                    opacity: 0,
                    duration: 1.25,
                }, '>2')
        },
        [MEDIA_QUERIES.sm.max]: () => {
            gsap.set($element, { clearProps: true })
            gsap.set([$bgImage, $quote], { clearProps: true })
            gsap.set($quote, { opacity: 0 })

            gsap.timeline(config)

                .to($quote, { 
                    opacity: 1, 
                    duration: 1.25,
                })
        
                .to($quote, {
                    opacity: 0,
                    duration: 1.25,
                }, '>2')
        },
    })

}

export const IconBarSection = function(selectorOrElement) {
    let $element = getElement(selectorOrElement)
    let $iconBar = document.querySelector(' .icon-bar')
    let $iconBarIcons = Array.from($iconBar.querySelectorAll('.icon'))
    let $items = Array.from(document.querySelectorAll('.icon-text-chart__item'))

    ScrollTrigger.matchMedia({

        [MEDIA_QUERIES['2xl'].min]: function() {

            ScrollTrigger.create({
                trigger: $element,
                pin: true,
                end: '+=800%',
            })

            gsap.set($iconBar, {clearProps: true})
            gsap.set($iconBar, {display: 'flex',})
            
            let servicesTl = gsap.timeline({
                scrollTrigger: {
                    trigger: $element,
                    scrub: true,
                    start: 'start center',
                    end: '+=900%'
                },
            })

            servicesTl.fromTo($iconBarIcons, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 10,
                stagger: 1,
            })
            
            $items.forEach($item => {
                let $reference = $iconBarIcons.filter($reference => $reference.dataset.service === $item.dataset.service)
                let $icon = $item.querySelector('.icon')
                let $text = $item.querySelector('.text')

                // Clear all inline styles
                gsap.set([ $item, $reference, $icon, $text ], {
                    clearProps: true,
                })
            
                if ($reference.length === 0) {
                    return
                }
            
                $reference = $reference[0]
        
                // Get position of reference icon
                let iconBbox = $icon.getBoundingClientRect()
                let referenceBbox = $reference.getBoundingClientRect()
                let x = referenceBbox.x - iconBbox.x
                let y = referenceBbox.y - iconBbox.y
                
                // Keep track of values to reverse the animation later
                let initial = {
                    icon: {
                        opacity: 0,
                        scale: 1,
                        x: x, 
                        y: y,
                    },
                    text: {
                        opacity: 0, 
                        x: -30
                    }
                }

                // Set initial values
                gsap.set($item, {
                    position: 'absolute',
                })
                gsap.set($text, initial.text)
                gsap.set($icon, initial.icon)
                
                // Define timeline per icon text chart item
                let tl = gsap.timeline()
            
                tl.to($reference, {
                    opacity: 0,
                    duration: 2,
                })
            
                tl.to($icon, {
                    x: 0, 
                    y: 0,
                    scale: 1,
                    opacity: 1,
                    duration: 4,
                }, '<')
            
                tl.to($text, {  
                    opacity: 1,
                    x: 0,
                    duration: 4,
                }, '<')
            
                tl.to($icon, Object.assign(initial.icon, {
                    duration: 4,
                }), '>10')
            
                tl.to($text, Object.assign(initial.text, {
                    duration: 4,
                }), '<')
            
                tl.to($reference, {
                    opacity: 1,
                    duration: 2,
                }, '<2')
            
                servicesTl.add(tl, '>2')
            })
            
            servicesTl.to($iconBarIcons, {
                opacity: 0,
                y: -30,
                duration: 20,
            }, '>10')
        },
        [MEDIA_QUERIES.xl.max]: function() {
            gsap.set($iconBar, { clearProps: true })

            ScrollTrigger.create({
                trigger: $element,
            })

            document.querySelectorAll('.icon-text-chart__item').forEach(($item) => {
                let $reference = $iconBarIcons.filter($reference => $reference.dataset.service === $item.dataset.service)
                let $icon = $item.querySelector('.icon')
                let $text = $item.querySelector('.text')
                gsap.set([$item, $reference, $icon, $text], { clearProps: true })
                IconTextTransition($item)
            })
        }
    })
}

export const ServicesIntroSection = ($element) => {
    let pinIt = isDesktop()

    ScrollTrigger.create({
        trigger: $element,
        pin: pinIt,
        end: '+=100%',
    })

    gsap.timeline({
        scrollTrigger: {
            trigger: $element,
            scrub: true,
            start: 'start bottom',
            end: '+=100%',
        }
    })
        .from($element.querySelector('.headline'), {
            y: 100,
            opacity: 0,
            ease: 'ease',
        })

        .from($element.querySelectorAll('.arrow'), {
            x: -100,
            opacity: 0,
            ease: 'ease',
        }, '<')

        .from($element.querySelectorAll('.text'), {
            x: -50,
            opacity: 0,
            ease: 'ease',
        }, '<')

}

export const FactsAndFiguresSection = (selectorOrElement) => {
    let $element = getElement(selectorOrElement)

}