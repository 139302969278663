import isString, { isElement } from 'lodash-es'
import {BREAKPOINTS} from './_variables.js'

export const isMobile = () => {
    let mobile = window.navigator.userAgent.match(/Android|iPhone|iPad|BlackBerry|IEMobile/)
    return !!(mobile && mobile.length > 0)
}

export const isDesktop = () => {
    return !isMobile()
}

/**
 * 
 * @param {string} breakpoint Values like `md`, `sm-min`, `lg-max` ... 
 */
export const media = (breakpoint) => {
    let key // xs, sm, md, ...
    let limit // min or max
    let query

    if (typeof breakpoint !== 'string') {
        throw new Error('Argument `breakpoints` must be of type string')
    }

    breakpoint = breakpoint.split('-')

    if (breakpoint.length === 1) {
        key = breakpoint[0]
    } else {
        key = breakpoint[0]
        limit = breakpoint[1]
    }

    if (!Object.keys(BREAKPOINTS).includes(key)) {
        throw new Error(`${breakpoint} is not a valid breakpoint`)
    }

    let {min, max} = BREAKPOINTS[key]

    if ('max' === limit && !max) {
        throw new Error(`No max value defined for breakpoint ${key}`)
    }

    switch (limit) {
        case 'max':
            query = `screen and (max-width: ${max}px)`
            break;
        default:
            query = `screen and (min-width: ${min}px)`
    }

    let mq = window.matchMedia(query)
    return mq.matches
}

export const MQ = {
    minWidth(key) {
        if (!Object.keys(BREAKPOINTS).includes(key)) {
            throw new Error(`${key} is not a valid breakpoint`)
        }

        let mq = window.matchMedia(`screen and (min-width: ${BREAKPOINTS[key].min}px)`)
        return mq.matches
    },
    maxWidth(key) {
        if (!Object.keys(BREAKPOINTS).includes(key)) {
            throw new Error(`${key} is not a valid breakpoint`)
        }
        
        let mq = window.matchMedia(`screen and (max-width: ${BREAKPOINTS[key].min}px)`)
        return mq.matches
    },
    minMaxWidth(minKey, maxKey) {
        if (!Object.keys(BREAKPOINTS).includes(minKey)) {
            throw new Error(`${minKey} is not a valid breakpoint`)
        }
        
        if (!Object.keys(BREAKPOINTS).includes(maxKey)) {
            throw new Error(`${maxKey} is not a valid breakpoint`)
        }

        let mq = window.matchMedia(`screen (min-width: ${BREAKPOINTS[minKey].min}px) and (max-width: ${BREAKPOINTS[maxKey].max}px)`)
        return mq.matches
    }
}

/**
 * @param {string|HTMLElement|NodeList} selectorOrElement 
 * @returns 
 */
export const getElement = (selectorOrElement) => {

    if (isElement(selectorOrElement)) {
        return selectorOrElement
    }
    
    if (isString(selectorOrElement)) {
        let $elements = document.querySelectorAll(selectorOrElement)
        
        if (!$elements) {
            return null;
        }

        if ($elements.length === 1) {
            return $elements[0]
        }

        return $elements
    }

    throw new Error('Element must be string or dom element')
}